import React, { Component } from 'react';
import axios from 'axios';
import * as CSV from "csvtojson";
import _ from 'underscore';

import Overview from './Overview';
import QuizTemplate from './QuizTemplate';
import ManageFlightschool from './ManageFlightschool';
import CreateFlightschool from './CreateFlightschool';
import ManageAnswers from './ManageAnswers';
import Menu from './Menu';
import Settings from './Settings';
import ChooseCatalog from './ChooseCatalog';
import Loading from './Loading';
import ReminderBox from './ReminderBox';

import API from '../utils/resources/api.js';
import utils from '../utils/utils';

import defaults from "../utils/defaults";

import clouds from '../components/img/clouds.jpg';

const OktaJwtVerifier = require('@okta/jwt-verifier');
const OKTA_ENDPOINT = 'https://dev-911881.okta.com/oauth2/default';

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      settings: {
        noConfirmationOnClickingAnswer: true,
        showQuestionsFromFlightschool: false,
      },
      reminder: {
        active: null,
        options: {},
        text: {},
        actions: {},
      },
      forceOpenChooseDialog: false,
      questions: {
        "data": null,
        "flightschool_questions": null,
        "flightschool_scenarios": null,
      },
      flightschool_categories: null,
      flightschool_answers: null,
      flightschool_documents: null,
      radio_categories: null,
      manageFlightschool: process.env.MANAGE_FLIGHT_SCHOOL_OVERLAY,
      createNewATO: process.env.MANAGE_ALL_FLIGHT_SCHOOLS_OVERLAY,
      manageAnswers: process.env.MANAGE_ANSWERS_OVERLAY,
    };

    // Set default axios headers
    const idToken = JSON.parse(localStorage.getItem('okta-token-storage'));
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + idToken.accessToken.accessToken;
    axios.defaults.headers.common["Content-Type"] = "application/json";

    // Add a request interceptor to add the "loading" class
    axios.interceptors.request.use(config => {
      document.body.classList.add('loading');
      return config;
    });

    // Add a response interceptor to remove the "loading" class
    axios.interceptors.response.use(
      response => {
        document.body.classList.remove('loading');
        return response;
      },
      error => {
        document.body.classList.remove('loading');
        if (error && error.response && error.response.status && error.response.status === 401) {
          window.location.href = "/login";
        }
        return Promise.reject(error);
      }
    );

    this.startQuiz = this.startQuiz.bind(this);
    this.changeQuestionModus = this.changeQuestionModus.bind(this);
    this.displayOverview = this.displayOverview.bind(this);
    this.forceOpenChooseCatalog = this.forceOpenChooseCatalog.bind(this);
    this.getCSVAndParseToJSON = this.getCSVAndParseToJSON.bind(this);
    this.noConfirmationOnClickingAnswer = this.noConfirmationOnClickingAnswer.bind(this);
    this.getContentFromFlightschool = this.getContentFromFlightschool.bind(this);
    this.getOlderQuestions = this.getOlderQuestions.bind(this);
    this.getOrCreateLocalStorageSettings = this.getOrCreateLocalStorageSettings.bind(this);
    this.showQuestionsOfFlightschool = this.showQuestionsOfFlightschool.bind(this);
    this.playScenario = this.playScenario.bind(this);
    this.showHomework = this.showHomework.bind(this);
    this.showExam = this.showExam.bind(this);
    this.onSelectScenario = this.onSelectScenario.bind(this);
    this.updateReminder = this.updateReminder.bind(this);
    this.dataIsReady = this.dataIsReady.bind(this);
    this.manageFlightschool = this.manageFlightschool.bind(this);
    this.createNewATO = this.createNewATO.bind(this);
    this.manageAnswers = this.manageAnswers.bind(this);
    this.cancelReminder = this.cancelReminder.bind(this);
    this.closeCurrentPage = this.closeCurrentPage.bind(this);
    this.radioQuestions = this.radioQuestions.bind(this);
    this.updateUserSettings = this.updateUserSettings.bind(this);
    this.updatePersistedData = this.updatePersistedData.bind(this);
    this.getRadioQuestions = this.getRadioQuestions.bind(this);
    this.tokenVerifier = this.tokenVerifier.bind(this);
    this.handleInnerManagerActionCall = this.handleInnerManagerActionCall.bind(this);
    this.shouldRenderReminder = this.shouldRenderReminder.bind(this);
  }

  componentDidMount() {
    this.tokenVerifier();
  }

  tokenVerifier() {
    const idToken = JSON.parse(localStorage.getItem('okta-token-storage'));
    const oktaJwtVerifier = new OktaJwtVerifier({
      issuer: OKTA_ENDPOINT,
      cacheMaxAge: 60 * 60 * 1000 * 12,
    });
    oktaJwtVerifier.verifyAccessToken(idToken.accessToken.accessToken, 'api://default')
    .then(jwt => this.afterTokenVerification(idToken, jwt) )
    .catch(err => console.warn('token failed validation', err) );
  }

  async afterTokenVerification(idToken, jwt) {
    try {
      // Fetch user settings from database
      const result = await API.getUserData();
  
      if (result !== false) {
        // Update user data (settings, questions)
        this.updatePersistedData(result);
      }
  
      // Update state with user information
      this.setState({
        userinfo: idToken,
        isATO: (idToken && idToken.idToken && idToken.idToken.claims && idToken.idToken.claims.isATO) || false,
        atoDetails: result && result.atoDetails,
        isSuperAdmin: result && result.isSuperAdmin
      }, async () => {

        // get radio questions
        await this.getContentFromFlightschool();

        // get radio questions
        await this.getRadioQuestions();

        // initial get questions from flightschool
        await this.getOlderQuestions();

        // initial fetch all questions from CSV file { "ALW": [...], "MET": [...] }
        await this.getCSVAndParseToJSON();
      });
    } catch (err) {
      console.warn('Cannot get user data.', err);
    }
  }  

  updatePersistedData(result) {
    if (!result || result === "" || !_.isEmpty(result.settings)) {
      API.syncSettings();
      return;
    }

    // if user was created manually
    if (_.isEmpty(result.settings) && _.isEmpty(result.userdata)) {
      API.syncSettings();
      return;
    }

    // initial move from legacy settings to userdata.settings
    if (_.isEmpty(result.userdata)) {
      // copy everything from old settings to new userdata
      this.updateUserSettings(result);
      this.updateSyncedQuestions(result);
      API.syncSettings(function() {
        API.cleanup();
      });
    } else {
      this.updateUserSettings(result.userdata);
      this.updateSyncedQuestions(result.userdata);
    }
  }

  updateUserSettings(result) {
    const { ppltrainer, ppltrainer_settings, favorite_questions_collection } = result.settings;
    if (ppltrainer && !_.isUndefined(ppltrainer)) {
      localStorage.setItem('ppltrainer', JSON.stringify(ppltrainer));
    }
    if (ppltrainer_settings && !_.isUndefined(ppltrainer_settings)) {
      localStorage.setItem('ppltrainer_settings', JSON.stringify(ppltrainer_settings));
      const settings = this.state.settings;
      settings["noConfirmationOnClickingAnswer"] = ppltrainer_settings["noConfirmationOnClickingAnswer"];
      this.setState({ settings });
    }
    if (favorite_questions_collection && !_.isUndefined(favorite_questions_collection)) {
      localStorage.setItem('favorite_questions_collection', JSON.stringify(favorite_questions_collection));
    }
    if (result.lastSynced) {
      localStorage.setItem('last_synchronized_data', result.lastSynced);
    }
  }

  updateSyncedQuestions(result) {
    const { builtin, flightschool, older, radio } = result.settings.categories;
    
    // set builtin/default questions 
    const categories = defaults.defaultCategories; // Object.keys(builtin);
    _.each(categories, function(category) {
      const categoryData = builtin[category];
      if (categoryData && categoryData.length) {
        localStorage.setItem(category, JSON.stringify(categoryData));
      } else {
        localStorage.setItem(category, "[]");
      }
    });

    // set radio questions
    const radioCategories = ["RADIO_recht","RADIO_sonderbestimmungen","RADIO_technik", "RADIO_AZF", "RADIO_BZF1_BZF2"]; // Object.keys(radio);
    _.each(radioCategories, function(category) {
      const categoryData = radio[category];
      if (categoryData && categoryData.length) {
        localStorage.setItem(category, JSON.stringify(categoryData));
      } else {
        localStorage.setItem(category, "[]");
      }
    });

    // set older questions
    const olderCategories = ["OLDER_AGK","OLDER_PFA","OLDER_PFB","OLDER_PFH","OLDER_MET","OLDER_NAV","OLDER_COM","OLDER_ALW","OLDER_HPL","OLDER_OPR","OLDER_FPP","OLDER_NVFR"]; // Object.keys(older);
    _.each(olderCategories, function(category) {
      const categoryData = older[category];
      if (categoryData && categoryData.length) {
        localStorage.setItem(category, JSON.stringify(categoryData));
      } else {
        localStorage.setItem(category, "[]");
      }
    });

    // set flightschool questions
    const flightschoolQuestions = Object.keys(flightschool);
    if (flightschoolQuestions.length > 0) {
      _.each(flightschoolQuestions, function(category) {
        const categoryData = flightschool[category];
        if (categoryData && categoryData.length) {
          localStorage.setItem(category, JSON.stringify(categoryData));
        } else {
          localStorage.setItem(category, "[]");
        }
      });
    } else {
      // reset all 'FS_.*' entries
      const itemsStartingWithFS_ = Object.keys(localStorage).filter((key)=> key.startsWith("FS_"));
      _.each(itemsStartingWithFS_, function(entry) {
        localStorage.setItem(entry, "[]");
      });
    }

  }

  radioQuestionIsRequired(question, radioTyp, additionalFilename) {
    if (question.category) {
      const data = radioTyp.split("_");
      const country = data && data[0]; // e.g. AT
      let types = data && data[1] && data[1].toUpperCase(); // e.g. AFZ or UPGRADE-BFZ-AFZ

      types = types && types.split("-");
      if (types[0] === "UPGRADE") {
        return _.find(additionalFilename.split("-"), function(type) {
          return question.category.includes(type.toUpperCase());
        });
      } else if (country === 'AT') {
        if (types.includes( "BFZ")) {
          return question.category.includes("BFZ");
        } else if (types.includes( "EFZ")) {
          return question.category.includes("BFZ") || question.category.includes("EFZ");
        } else if (types.includes( "AFZ")) {
          return question.category.includes("BFZ") || question.category.includes("EFZ") || question.category.includes("AFZ");
        }
        return true;
      }
      return true;
    }
    return true;
  }

  async getRadioQuestions() {
    const storage = this.getLocalStorageData("ppltrainer");
    if (storage && storage.radio) {
      var { radioTyp, country } = storage;

      const paths = [];
      switch (radioTyp) {
        case 'DE_bzf1':
          paths.push("BZF1_BZF2");
          break;
        case 'DE_bzf2':
          paths.push("BZF1_BZF2");
          break;
        case 'DE_azf':
          paths.push("AZF");
          break;
        case 'AT_bfz':
          paths.push("recht--bfz");
          paths.push("sonderbestimmungen--bfz");
          paths.push("technik--bfz");
          break;
        case 'AT_efz':
          paths.push("recht--efz");
          paths.push("sonderbestimmungen--efz");
          paths.push("technik--efz");
          break;
        case 'AT_afz':
          paths.push("recht--afz");
          paths.push("sonderbestimmungen--afz");
          paths.push("technik--afz");
          break;
        case 'AT_upgrade-efz-afz':
          paths.push("recht--afz");
          paths.push("sonderbestimmungen--afz");
          paths.push("technik--afz");
          break;
        case 'AT_upgrade-bfz-afz':
          paths.push("recht--efz-afz");
          paths.push("sonderbestimmungen--efz-afz");
          paths.push("technik--efz-afz");
          break;
        case 'AT_upgrade-bfz-efz':
          paths.push("recht--efz");
          paths.push("sonderbestimmungen--efz");
          paths.push("technik--efz");
          break;
        default:
          return false;
      }

      const that = this;
      let radioQuestions = {};
      let radio_categories = [];
      let pathsIndex = 0;

      _.each(paths, function(file) {
        var filename = file.split("--")[0];
        var additionalFilename = file.split("--")[1];
        radio_categories.push({
          "category": filename,
          "id": "RADIO_" + filename,
          "type": radioTyp
        });
        axios.get('/data/radio/' + country + '/' + filename + '.json')
        .then(res => {
          const { data } = res;
          _.each(data, function(question) {
            if (that.radioQuestionIsRequired(question, radioTyp, additionalFilename)) {
              if (!radioQuestions[filename]) {
                radioQuestions[filename] = { "data": [] };
              }
              radioQuestions[filename].data.push(question);
            }
          });
          pathsIndex++;
        })
        .catch(err => {
          console.log(err);
          pathsIndex++;
        })
        .finally( res => {
          if (pathsIndex === paths.length) {
            const questions = that.state.questions;
            questions["radio_questions"] = radioQuestions;
            that.setState({ 
              questions,
              radio_categories,
            });
          }
        })
      });

    }
  }
  
  async getOlderQuestions() {
    // first get setting from LS and set 'settings' state
    const settings = this.state.settings;
    this.setState({ settings });  
    const globalSettings = this.getOrCreateLocalStorageSettings("ppltrainer");
    const lang = (globalSettings && globalSettings.language) || 'de';
  
    try {
      let olderQuestions = await axios.get(`/data/legacy/${lang}/old.json`);
      olderQuestions = (olderQuestions && olderQuestions.data) || {};
  
      let customQuestions = await axios.get(`/data/custom/${lang}/${lang}.json`);
      customQuestions = (customQuestions && customQuestions.data) || {};
  
      const olderAndCustomQuestions = {};
  
      _.each(olderQuestions, function (items, key) {
        olderAndCustomQuestions[key] = {
          "data": _.map(items, function (item) {
            return item;
          })
        };
      });
      // Add custom questions to existing olderQuestions
      _.each(customQuestions, function (items, key) {
        var customQuestionMap = _.map(items, function (item) { return item });
        if (!olderAndCustomQuestions[key]) {
          olderAndCustomQuestions[key] = { "data": customQuestionMap };
        } else {
          olderAndCustomQuestions[key].data = olderAndCustomQuestions[key].data.concat(customQuestionMap);
        }
      });
  
      const questions = { ...this.state.questions, older_questions: olderAndCustomQuestions };
      this.setState({ questions });

    } catch (err) {
      console.log(err);
    }
  }  

  async getContentFromFlightschool() {
    if (!this.state.atoDetails) return;
    // get questions after changed/updated "fragenkatalog-settings"
    // only execute when flightschool is
    const that = this;
    const atoDetails = this.state.atoDetails;
    const questions = this.state.questions;

    questions["flightschool_questions"] = this.prepareFlightschoolQuestions(atoDetails.questions);
    questions["flightschool_scenarios"] = this.prepareFlightSchoolScenarios(atoDetails.scenarios);
    const flightschool_categories = this.prepareFlightSchoolCategories(atoDetails.categories);
    const flightschool_answers = this.prepareFlightSchoolAnswers(atoDetails.answers);
    const flightschool_documents = this.prepareFlightSchoolDocuments(atoDetails.documents);

    this.setState({ 
      questions,
      flightschool_categories,
      flightschool_answers,
      flightschool_documents,
    });

    let { homework, exams } = atoDetails;

    async function fetchData() {
      try {
        if (exams) {
          // Zuerst API.getFinishedExams() aufrufen
          const result = await API.getFinishedExams();
          
          // get first homework which is not already done.
          const examAsHomework = _.find(exams, (hw) => !result.includes(hw.id));
    
          if (examAsHomework) {
            const selectedCategories = examAsHomework.selectedCategories.map((category) => ({
              category,
              id: category,
            }));
    
            examAsHomework.selectedCategories = selectedCategories;
    
            that.setState({
              homework: examAsHomework,
              reminder: that.updateReminder("exam"),
            });
          }
        }
    
        // TODO check if already show reminder
        if (homework && that.state.reminder.active === null) {
          // Dann API.getUserHomework() aufrufen
          const userHomework = await API.getUserHomework();
    
          // get first homework which is not already done.
          const userHomeworkResult = _.find(homework, (hw) => !userHomework.includes(hw.id));
    
          if (userHomeworkResult) {
            const selectedCategories = userHomeworkResult.selectedCategories.map((category) => ({
              category,
              id: category,
            }));
    
            userHomeworkResult.selectedCategories = selectedCategories;
    
            that.setState({
              homework: userHomeworkResult,
              reminder: that.updateReminder("homework"),
            });
          }
        }
      } catch (err) {
        console.warn('Cannot get user homework or finished exams.', err);
      }
    }
    
    // Aufruf der asynchronen Funktion
    fetchData();


    const settings = this.state.settings;
    const settingsFromStorage = this.getOrCreateLocalStorageSettings("ppltrainer_settings");
    if (settingsFromStorage && !_.isUndefined(settingsFromStorage["showQuestionsFromFlightschool"])) {
      settings["showQuestionsFromFlightschool"] = settingsFromStorage["showQuestionsFromFlightschool"];
      this.setState({ settings });
    }
  }

  onSelectScenario(scenario) {
    this.setState({
      reminder: this.updateReminder("scenario", scenario),
    });
  }

  prepareFlightschoolQuestions(data) {
    const obj = {};
    _.each(data, function (entry, index) {
      if (!obj[entry.category]) obj[entry.category] = { data: [] };
      const answer = {
        key: "FS_" + entry.category + "_" + entry.id,
        correctAnswer: 0,
        answers: [entry.answer1, entry.answer2, entry.answer3, entry.answer4],
        question: entry.question,
        category: entry.category,
        img: entry.image,
        isSecret: entry.hidden === true || entry.isSecret || false,
      };
      obj[entry.category].data.push(answer)
    });
    return obj;
  }

  prepareFlightSchoolCategories(data) {
    const obj = [];
    _.each(data, function (entry) {
      var element = {};
      element.category = entry;
      element.id = entry;
      obj.push(element);
    });
    return obj;
  }

  prepareFlightSchoolAnswers(data) {
    if (!data) return null;
    const lang = document.documentElement.lang;
    return data[lang];
  }

  prepareFlightSchoolDocuments(data) {
    return data;
  }

  prepareFlightSchoolScenarios(data) {
    return data;
  }

  async getCSVAndParseToJSON() {
    const questionsALL = {};
    const beautiyQuestionsALL = {};

    const that = this;
    let loopCount = 0;
    let categories = defaults.defaultCategories;
  
    const storage = this.getOrCreateLocalStorageSettings("ppltrainer");
  
    if (!storage.language && !storage.country) {
      this.setState({
        isLoading: false,
        forceOpenChooseDialog: true,
      });
      return;
    }
  
    function mergeObjects(obj1, obj2) {
      if (!obj1 || _.isEmpty(obj1)) {
        return obj2;
      }
      var result = {};
      for (var key in obj1) {
        if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
          result[key] = {
            data: obj1[key].data.concat(obj2[key].data)
          };
        } else {
          result[key] = { data: obj1[key].data };
        }
      }
      return result;
    }
  
    function handleResponse(obj, category, questionLanguage) {
      loopCount++;
  
      if (obj) {
        const categoryQuestions = that.createQuestionObject(category, obj);
        _.extend(questionsALL, categoryQuestions);
  
        const beautyCategoryQuestions = that.createQuestionObject(category, obj, { beautyQuestions: true });
        _.extend(beautiyQuestionsALL, beautyCategoryQuestions);
      }
  
      if (loopCount === categories.length) {
        const questions = that.state.questions;
        questions.data = questionsALL;
        questions.older_questions = mergeObjects(questions.older_questions, beautiyQuestionsALL);

        that.setState({ 
          questions,
          isLoading: false
        });
      }
    }
  

    // get very first user information to fetch correct data
    let { language } = storage;
    let version = "24.6";

    _.each(categories, function (category) {
      axios.get('/data/' + version + '/'+ language +'/' + category + '.csv')
      .then(res => {
        const { data } = res;
        CSV({
          delimiter: ";"
        })
          .fromString(data)
          .then((obj)=>{
            handleResponse(obj, category, language);
        });
      })
      .catch(err => {
        console.log(err);
        handleResponse(null, category, language);
      })
    });
  }  

  getLocalStorageData(value) {
    return JSON.parse(localStorage.getItem(value));
  }

  getOrCreateLocalStorageSettings(value) {
    const data = this.getLocalStorageData(value);
    if (data) return data;
    
    localStorage.setItem(value, "{}");
    return this.getLocalStorageData(value);
  }

  setLocalStorage(key, data) {
    if (!data) return false;
    localStorage.setItem(key, JSON.stringify(data));
  }

  headerExistsInCategory(header, keys, key) {
    if (!header) return false;
    const keyList = keys[key];
    const found = _.find(keyList, function (el) { return !_.isUndefined(header[el]) });
    return found;
  }

  parseCorrectAnswer(value) {
    if (value === "A") return 0;
    if (value === "B") return 1;
    if (value === "C") return 2;
    return 3;
  }

  getQuestionPicture(question) {
    // return Picture or Annex - don't difference between pictures and annex
    if (question["Picture"] && question["Picture"].length) return [question["Picture"]];
    if (question["Annex"].length) {
      const annex = question["Annex"].split(","); // return all available images and handle the rendering in Question.js
      return _.unique(annex);
    }
    return false;
  }

  createQuestionObject(category, data, options) {
    options = options || { beautyQuestions: false };

    const that = this;
    const obj = {};

    if (options.beautyQuestions) {
      // Filter not needed questions like BPL, SPL, PPLH, PPLA (depends on catalog)
      let unionQuestionDatabaseList = utils.getRequestedDatabaseQuestions(data[0]);
      data = data.filter(function(objekt) {
        const availableForThisCatalog = _.find(unionQuestionDatabaseList, function (cat) { return objekt[cat] && objekt[cat].length });
        return objekt.EXAM !== "" && availableForThisCatalog;
      });

    } else {
      data = data.filter(function(objekt) {
        return objekt.TRAINING !== "";
      });

    }

    const computedData = _.map( data, function (question) {
      const entry = question;
      entry.key = category + "_" + question["Question ID"];
      entry.category = category;
      entry.question = question["Question"];
      entry.correctAnswer = that.parseCorrectAnswer(question["Correct"]);
      entry.answers = [question["A"],question["B"],question["C"],question["D"]];

      if (that.getQuestionPicture(question)) entry.img = that.getQuestionPicture(question);

      return entry;
    });

    obj[category] = {
      "data": computedData
    };

    return obj;
  }

  displayOverview() {
    this.setState({
      mode: null,
      categories: null,
      scenario: null,
    });
  }

  startQuiz(options) {
    const { mode, categories } = options;

    // reset "prüfungs-modus" (simulation)
    if (!this.getLocalStorageData("ppltrainer_exam_session")) {
      localStorage.setItem("ppltrainer_exam_session", "{}");
    }

    this.setState({
      mode,
      categories,
      isHomeworkAndHasID: null,
      isExamAndHasID: null,
      homeworkCategories: null,
      homeworkQuestionCount: null,
      homeworkExamPercentage: null,
      reminder: this.updateReminder(),
    });
  }

  getHeaderStyle() { 
    return { backgroundImage: `url(${clouds})` }
  }

  updateReminder(value, options) {
    options = options || {};
    const that = this;
    const reminder = this.state.reminder;
    if (!value) {
      reminder.active = null;
      reminder.options = {};
      reminder.text = {};
      reminder.actions = {};
    } else {
      reminder.active = value;
      switch(value) {
        case "homework": 
          reminder.text.headline = "homework_found";
          reminder.text.description = "homework_found_description";
          reminder.text.submitText = "show_homework";
          reminder.actions.submit = that.showHomework;
          reminder.actions.cancel = this.cancelReminder;
          break;
        case "exam":
          reminder.text.headline = "exam_found";
          reminder.text.description = "exam_found_description";
          reminder.text.submitText = "show_exam";
          reminder.actions.submit = that.showExam;
          reminder.actions.cancel = this.cancelReminder;
          break;
        case "scenario":
          reminder.text.headline = "scenario";
          reminder.text.additionalHeadline = options.title;
          reminder.text.additionalDescription = options.description;
          reminder.text.submitText = "go_scenario";
          reminder.actions.submit = that.playScenario;
          reminder.actions.cancel = this.cancelReminder;
          reminder.options = options;
          break;
        default:
          return reminder;
      }
    }
    return reminder;
  }

  async changeQuestionModus() {
    this.setState({
      forceOpenChooseDialog: false,
      isLoading: true,
    });
    await this.getContentFromFlightschool();
    await this.getRadioQuestions();
    await this.getOlderQuestions();
    await this.getCSVAndParseToJSON();
  }

  forceOpenChooseCatalog() {
    this.setState({ forceOpenChooseDialog: true });
  }

  showQuestionsOfFlightschool(show) {
    const settings = this.state.settings;
    settings["showQuestionsFromFlightschool"] = show;

    const settingsFromStorage = this.getOrCreateLocalStorageSettings("ppltrainer_settings");
    settingsFromStorage["showQuestionsFromFlightschool"] = show;
    this.setLocalStorage("ppltrainer_settings", settingsFromStorage);

    this.setState({ settings }, () => this.forceUpdate());
  }

  noConfirmationOnClickingAnswer(noConfirmation) {
    const settings = this.state.settings;
    settings["noConfirmationOnClickingAnswer"] = noConfirmation;

    const settingsFromStorage = this.getOrCreateLocalStorageSettings("ppltrainer_settings");
    settingsFromStorage["noConfirmationOnClickingAnswer"] = noConfirmation;
    this.setLocalStorage("ppltrainer_settings", settingsFromStorage);

    this.setState({ settings }, () => this.forceUpdate());
  }

  dataIsReady() {
    const questions = this.state.questions.data;
    return questions !== null;
  }

  radioQuestions() {
    const settingsFromStorage = this.getOrCreateLocalStorageSettings("ppltrainer");
    return settingsFromStorage && settingsFromStorage.radio;
  }

  manageFlightschool() {
    this.closeCurrentPage();
    this.setState({ manageFlightschool: true });
  }

  createNewATO() {
    this.closeCurrentPage();
    this.setState({ createNewATO: true });
  }

  manageAnswers() {
    this.closeCurrentPage();
    this.setState({ manageAnswers: true });
  }

  closeCurrentPage() {
    this.setState({
      createNewATO: false,
      manageFlightschool: false,
      manageAnswers: false,
    });
  }

  showHomework() {
    const homework = this.state.homework;
    const homeworkCategories = homework.selectedCategories;
    const homeworkQuestionCount = (homework.questionCount && parseInt(homework.questionCount)) || 16;
    const homeworkExamPercentage = (homework.examPercentage && parseInt(homework.examPercentage)) || null;

    // reset "prüfungs-modus" (simulation)
    localStorage.setItem("ppltrainer_exam_session", "{}");

    this.setState({
      mode: "simulation",
      homeworkCategories,
      homeworkQuestionCount,
      homeworkExamPercentage,
      reminder: this.updateReminder(),
      isHomeworkAndHasID: homework.id,
    });
  }

  // similar to homework but with other questions
  showExam() {
    const exam = this.state.homework;
    const homeworkCategories = exam.selectedCategories;
    const homeworkQuestionCount = (exam.questionCount && parseInt(exam.questionCount)) || 16;
    const homeworkExamPercentage = (exam.examPercentage && parseInt(exam.examPercentage)) || null;


    // reset "prüfungs-modus" (simulation)
    localStorage.setItem("ppltrainer_exam_session", "{}");

    this.setState({
      mode: "simulation",
      homeworkCategories,
      homeworkQuestionCount,
      homeworkExamPercentage,
      reminder: this.updateReminder(),
      isExamAndHasID: exam.id,
    });
  }

  playScenario() {
    const { id, questions } = this.state.reminder.options;
    
    this.setState({
      mode: "scenario",
      scenario: { id, questions },
      reminder: this.updateReminder(),
    })
  }

  cancelReminder() {
    this.setState({
      reminder: this.updateReminder(),
    });
  }

  handleInnerManagerActionCall(options) {
    const { action } = options;

    // close Manager
    this.setState({ 
      manageFlightschool: null,
      printExam: null,
    });

    switch (action) {
      case "startExam": 
        this.startQuiz({ mode: "simulation", categories: [] });
        break;
      case "printExam":
        this.setState({ printExam: true, mode: "simulation" });
        break;
      case "startClassExam":
        this.setState({ classExam: true, mode: "simulation" });
        break;
      case "startStudentsExam":
        this.setState({ studentsExam: true, mode: "simulation" });
        break;
      default:
        break;
    }
  }

  shouldRenderReminder() {
    const reminder = this.state.reminder.active;
    return reminder === "homework" || reminder === "exam" || reminder === "scenario";
  }

  render() {
    if (this.state.isLoading) return <Loading />
    if (!this.dataIsReady()) return <ChooseCatalog forceOpen={this.state.forceOpenChooseDialog} isATO={this.state.isATO} changed={this.changeQuestionModus} />
    return (
      <div className="trainer-container">
        <div className="ppl-menu-container">
          <Menu 
            userinfo={this.state.userinfo}
            isATO={this.state.isATO}
            isSuperAdmin={this.state.isSuperAdmin}
            onManageMyFlightschool={this.manageFlightschool}
            onCreateNewATO={this.createNewATO}
            manageAnswers={this.manageAnswers}
          />
          <Settings
            onToggleTrainingsModel={this.noConfirmationOnClickingAnswer}
            onToggleShowQuestionsOfFlightschool={this.showQuestionsOfFlightschool}
            settings={this.state.settings}
            questions={this.state.questions}
            onClickChooseCatalog={this.forceOpenChooseCatalog}
            onClickSyncSettings={API.syncSettings}
            atoDetails={this.state.atoDetails}
          />
        </div>
        <div className="ppl-header" style={this.getHeaderStyle()}>
          <div className="gradient"></div>
        </div>
        <div className="ppl-content">
        {
          this.state.manageFlightschool ?
            <div>
              <ManageFlightschool
                onBack={this.closeCurrentPage}
                onCallInnerManagerAction={this.handleInnerManagerActionCall}
                userinfo={this.state.userinfo}
                questions={this.state.questions}
              />
            </div>
          :
          this.state.createNewATO ?
            <div>
              <CreateFlightschool
                onBack={this.closeCurrentPage}
                userinfo={this.state.userinfo}
              />
            </div>
          :
          this.state.manageAnswers ?
            <div>
              <ManageAnswers
                onBack={this.closeCurrentPage}
                questions={this.state.questions}
              />
            </div>
          :
          this.state.mode ?
            <QuizTemplate
              userinfo={this.state.userinfo}
              questions={this.state.questions}
              scenario={this.state.scenario}
              settings={this.state.settings}
              mode={this.state.mode}
              isHomeworkAndHasID={this.state.isHomeworkAndHasID}
              isExamAndHasID={this.state.isExamAndHasID}
              homeworkCategories={this.state.homeworkCategories}
              homeworkQuestionCount={this.state.homeworkQuestionCount}
              homeworkExamPercentage={this.state.homeworkExamPercentage}
              categories={this.state.categories}
              flightschool_categories={this.state.flightschool_categories}
              flightschool_answers={this.state.flightschool_answers}
              flightschool_documents={this.state.flightschool_documents}
              radio_categories={this.state.radio_categories}
              onReset={this.displayOverview}
              atoDetails={this.state.atoDetails}
              printExam={this.state.printExam}
            />
          :
            <Overview
              flightschool_categories={this.state.flightschool_categories}
              flightschool_answers={this.state.flightschool_answers}
              radio_categories={this.state.radio_categories}
              startQuiz={this.startQuiz}
              questions={this.state.questions}
              enableFlightschoolContent={this.state.settings["showQuestionsFromFlightschool"]}
              enableRadioQuestions={this.radioQuestions()}
              onSelectScenario={this.onSelectScenario}
            />
        }
        {
          <ReminderBox
            show={this.shouldRenderReminder()}
            reminder={this.state.reminder}
          />
        }
        </div>
        <ChooseCatalog forceOpen={this.state.forceOpenChooseDialog} isATO={this.state.isATO} changed={this.changeQuestionModus} />
        <div className="placeholder-height"></div>
      </div>
    );
  }
}

export default Main;
