import React, { Component } from 'react';
import { Icon, Modal,  Form, Radio, Dropdown, Image, Checkbox } from 'semantic-ui-react'
import PaypalExpressBtn from 'react-paypal-express-checkout';
import _ from 'underscore';
import './css/login.css';

import img_visa from '../landingpage/images/payments/visa.png';
import img_mastercard from '../landingpage/images/payments/mastercard.png';
import img_paypal from '../landingpage/images/payments/paypal.png';
import img_sofort from '../landingpage/images/payments/sofort.png';
import img_amex from '../landingpage/images/payments/amex.png';

import i18n from '../utils/i18n';

// DEFAULT PRICES IN CENT
const PRICE_6_MONTHS = 6900;
const PRICE_12_MONTHS = 7900;
const PRICE_24_MONTHS = 11700;
const PRICE_HEADSET = 18900;
const PRICE_FLIGHTCOMPUTER = 3400;
const PRICE_CHEATSHEET = 800;

class PaymentModal extends Component {
  constructor(props) {
    super(props);

    let time;
    let defaultProduct = this.props.defaultProduct;
    switch (defaultProduct) {
      case "basic":
        time = "6";
        break;
      case "platin":
        time = "24";
        break;
      default:
        time = "12";
    }

    this.state = {
      value: 'creditcard',
      time: time,
      paymentOptions: {},
      sofortInput: false,
      name_first: null,
      name_last: null,
      address_plz: null,
      address_street: null,
      email: null,
      confirmEmail: null,
      country: "AT",
      showDiscounts: false,
      productAndPaymentMethodSelected: false,
      products: {
        flightcomputer: false,
        headset: false,
        cheatsheet: defaultProduct === "platin" ? true : false,
      },
      refreshPayPal: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.updateProducts = this.updateProducts.bind(this);
    this.sofortSelection = this.sofortSelection.bind(this);
  }

  handleChange(value) {
    this.setState({
      value,
    })
  }

  handleTimeChange(time) {
    this.setState({
      time,
    })
  }

  paypalEnv() {
    return "production"
    // return "sandbox";
  }

  paypalClient() {
    return {
      sandbox:    'Ad7Jnn3gSx5wteI98T7r9ejMj_cmpqnMpVCJIu6sCu6mrcHCv-nf1xb4hh7JqgrY_TAsr2gY4SUzrrJL',
      production: 'Af1D2PyIxcEXifC8grQhaWuQ7-MEdUCu9QMXz--lWkqgH05tAilLM6eh4X2NSR1maigpD09ejodasl0S',
    }
  }

  getBorderColorClass(time) {
    if (time === this.state.time) return "green";
    return "default";
  }

  getPaymentMethodClass(value) {
    if (value === this.state.value) return "green";
    return "default";
  }

  getPriceAsIntCentForGivenMonths(months) {
    if (months === 6) return PRICE_6_MONTHS;
    if (months === 12) return PRICE_12_MONTHS;
    if (months === 24) return PRICE_24_MONTHS;
  }

  getFinalPriceAsHumanReadableString(months) {
    var price = this.getFinalPrice(months);
    return "€" + price;
  }

  getPriceForCheatSheet(months) {
    if (months === 24) return 0;
    return PRICE_CHEATSHEET;
  }

  getReadablePriceForCheatSheet() {
    if (this.state.time === "24") return i18n("free_of_charge");
    return "€" + PRICE_CHEATSHEET / 100 + ",--";
  }

  getFinalPrice(months) {
    var price = this.getPriceAsIntCentForGivenMonths(months);
    if (this.state.products["headset"]) {
      price += PRICE_HEADSET;
    } 
    if (this.state.products["flightcomputer"]) {
      price += PRICE_FLIGHTCOMPUTER;
    }
    if (this.state.products["cheatsheet"]) {
      price += this.getPriceForCheatSheet(months);
    }
    price = price / 100;
    return price;
  }

  getPrice() {
    const time = this.state.time;
    if (time === "12") {
      return this.getFinalPriceAsHumanReadableString(12)
    } else if (time === "24") {
      return this.getFinalPriceAsHumanReadableString(24)
    }
    return this.getFinalPriceAsHumanReadableString(6)
  }

  getTotalPrice() {
    const time = this.state.time;
    if (time === "12") {
      return this.getFinalPrice(12);
    } else if (time === "24") {
      return this.getFinalPrice(24);
    }
    return this.getFinalPrice(6);
  }

  sofortSelection() {
    this.setState({ productAndPaymentMethodSelected: false });
    if (this.state.sofortInput) {
      const {name_first, name_last, address_plz, address_street, email, confirmEmail, country} = this.state;
      if (email !== confirmEmail || !name_first || !name_last || !address_plz || !address_street || !email) return false; // TODO Error handling
      this.props.onSelect(this.state.value, this.state.time, this.state.products, { user: { name_first, name_last, address_street, address_plz, email}, country: country})
    } else {
      this.setState({
        sofortInput: true,
      });
    }
  }

  nextStep() {
    const state = {
      productAndPaymentMethodSelected: true
    };
    if (this.props.defaultProduct === "package") {
      state.products = {
        flightcomputer: true,
        headset: true
      };
    }
    if (this.state.time === "24") {
      state.products = _.extend(state.products || {}, { cheatsheet: true });
    }
    this.setState(state);
  }

  updateProducts(product) {
    var that = this;
    var products = this.state.products;
    var val = !this.state.products[product];
    products[product] = val;
    this.setState({ 
      products,
      refreshPayPal: false,
    }, () => this.forceUpdate());
    window.setTimeout(function() {
      that.setState({
        refreshPayPal: true,
      }, () => that.forceUpdate());
    },200);
  }

  render() {
    const buttonStyles = {
      color:  'blue',
      shape:  'pill',
      label:  'pay',
      height: 40
    }
    const countryOptions = [
      { key: 'at', value: 'AT', flag: 'at', text: 'Österreich' },
      { key: 'de', value: 'DE', flag: 'de', text: 'Deutschland' },
    ]
    return (
      <div>
        <Modal className="payment-modal" dimmer="inverted" size="small" open={true} onClose={this.props.onAbort} closeOnEscape={true} closeOnDimmerClick={false}>
        <Modal.Header>{i18n("buy_ppltrainer")}</Modal.Header>
        <div className="payment-total-price-header">{i18n("totalPrice")}: {this.getPrice()}</div>
        <Modal.Content className={`payment ${this.state.productAndPaymentMethodSelected || this.state.sofortInput ? "summarize-payment": ""}`}>
          <Modal.Description>
          <div className="select-account-duration-container">
          < Form>
            <p><b className="fs-13">{i18n("runtime")}:</b> <span className='runtime-description'>{i18n("runtime_description")}</span></p>
              <Form.Field className={`half-width border-color-${this.getBorderColorClass("6")}`}>
                <Radio
                  label={i18n("month_6")}
                  name='time'
                  value='6'
                  checked={this.state.time === '6'}
                  onChange={e => this.handleTimeChange('6', e)}
                />
              </Form.Field>
              <Form.Field className={`half-width border-color-${this.getBorderColorClass("12")}`}>
                <Radio
                  label={i18n("month_12")}
                  name='time'
                  value='12'
                  checked={this.state.time === '12'}
                  onChange={e => this.handleTimeChange('12', e)}
                />
              </Form.Field>
              <Form.Field className={`half-width border-color-${this.getBorderColorClass("24")}`}>
                <Radio
                  label={i18n("month_24")}
                  name='time'
                  value='24'
                  checked={this.state.time === '24'}
                  onChange={e => this.handleTimeChange('24', e)}
                />
              </Form.Field>
            </Form>
          </div>
          < Form className="payment-container-selection">
            <p><b className="fs-13">{i18n("paymentmethod")}:</b> <span className='runtime-description'>{i18n("paymentmethod_description")}</span></p>
              <Form.Field className={`border-color-${this.getPaymentMethodClass("creditcard")}`}>
                <Radio
                  label={i18n("creditcard")}
                  name='radioGroup'
                  value='creditcard'
                  checked={this.state.value === 'creditcard'}
                  onChange={e => this.handleChange('creditcard', e)}
                />
                <div className="payment-overview-pay">
                  <img className="card-pay" src={img_visa} alt="Visa" />
                  <img className="card-pay" src={img_mastercard} alt="Mastercard" />
                  <img className="card-pay" src={img_amex} alt="American Express" />
                </div>
              </Form.Field>
              {<Form.Field className={`border-color-${this.getPaymentMethodClass("sofort")}`}>
                <Radio
                  label='Sofort'
                  name='radioGroup'
                  value='sofort'
                  checked={this.state.value === 'sofort'}
                  onChange={e => this.handleChange('sofort', e)}
                />
                <div className="payment-overview-pay">
                  <img className="card-pay" src={img_sofort} alt="Sofort" />
                </div>
              </Form.Field>}
              <Form.Field className={`border-color-${this.getPaymentMethodClass("paypal")}`}>
                <Radio
                  label='PayPal'
                  name='radioGroup'
                  value='paypal'
                  checked={this.state.value === 'paypal'}
                  onChange={e => this.handleChange('paypal', e)}
                />
                <div className="payment-overview-pay">
                  <img className="card-pay" src={img_paypal} alt="PayPay" />
                </div>
              </Form.Field>
            </Form>

            {
              this.state.sofortInput ? 
              <div className="mt-userdata">
                <p><b>{i18n("userData")}</b></p>

                <div className="has-float-label half-width">
                  <input id="name_first" type="text" placeholder={i18n("name_first")} onChange={(e) => this.setState({ name_first: e.target.value })} />
                  <label htmlFor="name_first">{i18n("name_first")}</label>
                </div>

                <div className="has-float-label half-width">
                  <input id="name_last" type="text" placeholder={i18n("name_last")} onChange={(e) => this.setState({ name_last: e.target.value })} />
                  <label htmlFor="name_last">{i18n("name_last")}</label>
                </div>

                <div className="has-float-label half-width">
                  <input id="address_street" type="text" placeholder={i18n("address_street")} onChange={(e) => this.setState({ address_street: e.target.value })} />
                  <label htmlFor="address_street">{i18n("address_street")}</label>
                </div>

                <div className="has-float-label half-width">
                  <input id="address_plz" type="text" placeholder={i18n("address_plz")} onChange={(e) => this.setState({ address_plz: e.target.value })} />
                  <label htmlFor="address_plz">{i18n("address_plz")}</label>
                </div>

                <div className="has-float-label">
                  <input id="emailInput" type="text" placeholder="E-Mail" onChange={(e) => this.setState({ email: e.target.value })} />
                  <label htmlFor="emailInput">E-Mail</label>
                </div>

                <div className="has-float-label">
                  <input id="confirmEmailInput" type="text" placeholder={i18n("confirmEmail")} onChange={(e) => this.setState({ confirmEmail: e.target.value })} />
                  <label htmlFor="confirmEmailInput">{i18n("confirmEmail")}</label>
                </div>

                <Dropdown
                  placeholder='Österreich'
                  fluid
                  selection
                  onChange={(e, data) => this.setState({ country: data.value})}
                  options={countryOptions}
                />

                <div className="ruecktritt-container">
                  {i18n("ruecktrittsrecht")}
                </div>

                <button onClick={e => this.sofortSelection()} autoFocus className="button button-primary btn-checkout">{i18n("nextStep")} <Icon name="arrow right"/></button>

              </div>
              : null
            }

            {
              // initial screen (payment + product selection)
              this.state.productAndPaymentMethodSelected ? 
              <div className="discount-container-payment">
                
                <span className="discount-header">{i18n("recomanded_for_you")}:</span> 

                <div className="discount-container-parent-wrapper">
                  <div className="discount-container-inner-wrapper">
                    <div className={`discount-container-inner ${this.state.products["flightcomputer"] ? "active": ""}`} onClick={e => this.updateProducts("flightcomputer")}>
                      <div className="price-sign">€34,--</div>
                      <Image src="/img/flightcomputer.jpg" className="promo-image img-placeholder-product" />
                      <div className='confirm-include-container'>
                        <b>{i18n("e6b_flightcomputer")}</b>
                        <Checkbox checked={this.state.products["flightcomputer"]} label={i18n("confirm-include-to-cart")} />
                      </div>
                    </div>
                  </div>

                  <div className="discount-container-inner-wrapper pad-fix">
                    <div className={`discount-container-inner ${this.state.products["headset"] ? "active": ""}`} onClick={e => this.updateProducts("headset")}>
                      <div className="price-sign">€189,--</div>
                      <Image src="/img/headset.jpg" className="promo-image img-placeholder-product" />
                      <div className='confirm-include-container'>
                        <b>{i18n("aviation_headset")}</b>
                        <Checkbox checked={this.state.products["headset"]} label={i18n("confirm-include-to-cart")} />
                      </div>
                    </div>
                  </div>

                  <div className="discount-container-inner-wrapper pad-fix">
                    <div className={`discount-container-inner ${this.state.products["cheatsheet"] ? "active": ""}`} onClick={e => this.updateProducts("cheatsheet")}>
                      <div className="price-sign">{this.getReadablePriceForCheatSheet()}</div>
                      <Image src="/img/cheat_sheet.jpg" className="promo-image img-placeholder-product cheat-sheet-img" />
                      <div className='confirm-include-container'>
                        <b>Cheat Sheet</b>
                        <Checkbox checked={this.state.products["cheatsheet"]} label={i18n("confirm-include-to-cart")} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`discount-container-free-items ${this.state.products["headset"] || this.state.products["flightcomputer"] ? "active": ""}`}>
                  <Checkbox checked={this.state.products["headset"] || this.state.products["flightcomputer"]} className="confirm-include-free-items" label="" />
                  <ul>
                    <li>-<b className="high-size">{i18n("free_shipping")}</b></li>
                    <li>-<b className="high-size">{i18n("free_navigationtriangle")}</b></li>
                    <li>-<b className="high-size">{i18n("free_key")}</b></li>
                  </ul>
                  <Image src="/img/free-items.jpg" className="promo-image promo-image-free" />
                </div>

                <div className="ruecktritt-container">
                {i18n("ruecktrittsrecht")}
                </div>
                {
                  this.state.value === 'creditcard' ?
                  <button onClick={e => this.props.onSelect(this.state.value, this.state.time, this.state.products, e)} className="button button-primary btn-checkout">{i18n("nextStep")} <Icon name="arrow right"/></button>
                  : this.state.value === 'sofort' ?
                  <button onClick={e => this.sofortSelection()} className="button button-primary btn-checkout">{i18n("nextStep")} <Icon name="arrow right"/></button>
                  : this.state.value === 'paypal' ?
                  <div className="paypal-checkout-btn">
                    {
                      this.state.refreshPayPal ?
                        <PaypalExpressBtn
                        date={new Date()}
                        env={this.paypalEnv()}
                        style={buttonStyles}
                        client={this.paypalClient()}
                        currency="EUR"
                        total={this.getTotalPrice()}
                        onError={this.props.onPayPalError}
                        onSuccess={this.props.onPayPalSuccess}
                        onCancel={this.props.onPayPalCancel}
                      />
                      : null
                    }
                  </div>
                  : null
                }
              </div>
              :
              !this.state.sofortInput ?
              <button onClick={e => this.nextStep()} className="button button-primary btn-checkout">{i18n("nextStep")} <Icon name="arrow right"/></button>
              : null
            }

          </Modal.Description>
        </Modal.Content>
      </Modal>
     </div>
    );
  }
}

export default PaymentModal;
