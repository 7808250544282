import _ from 'underscore';

const utils = {};

utils.generateWeighedListOfNumbers = function generateWeighedListOfNumbers () {
  const weighed_list = [];

  // weight of categories
  const list = ['1', '2', '3', '4', '5'];
  const weight = [0.4, 0.25, 0.17, 0.1, 0.08];

  // Loop over weights
  for (let i = 0; i < weight.length; i++) {
    const multiples = weight[i] * 100;
       
    // Loop over the list of items
    for (let j = 0; j < multiples; j++) {
        weighed_list.push(list[i]);
    }
  }
   
  return weighed_list;
};

utils.shuffle = function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

utils.getLocalStorageData = function getLocalStorageData() {
  let data = JSON.parse(localStorage.getItem('ppltrainer'));
  if (!data) {
    data = {
      motor: true,
      heli: false,
      segel: false,
      ballon: false,
      country: 'AT',
      language: "de",
    }
  }
  return data;
}

// get categories from CSV (new and old excel layout)
utils.getRequestedDatabaseQuestions = function getRequestedDatabaseQuestions(header) {
  const settings = utils.getLocalStorageData();
  let unionQuestionDatabaseList = [];

  function headerExistsInCategory(header, keys, key) {
    if (!header) return false;
    const keyList = keys[key];
    const found = _.find(keyList, function (el) { return !_.isUndefined(header[el]) });
    return found;
  }

  const keys = {
    PPLA: ["PPLA"],
    PPLH: ["PPLH"],
    SPL: ["SPL"],
    BPL: ["BPLH", "BPLG"],
  };
    
  if (settings.motor && headerExistsInCategory(header, keys, "PPLA")) {
    unionQuestionDatabaseList = _.union(unionQuestionDatabaseList, keys["PPLA"]);
  }

  if (settings.heli && headerExistsInCategory(header, keys, "PPLH")) {
    unionQuestionDatabaseList = _.union(unionQuestionDatabaseList, keys["PPLH"]);
  }

  if (settings.segel && headerExistsInCategory(header, keys, "SPL")) {
    unionQuestionDatabaseList = _.union(unionQuestionDatabaseList, keys["SPL"]);
  }

  if (settings.ballon && headerExistsInCategory(header, keys, "BPL")) {
    unionQuestionDatabaseList = _.union(unionQuestionDatabaseList, keys["BPL"]);
  }
  
  return unionQuestionDatabaseList;
}



export default utils;